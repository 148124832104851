import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';

import Text, { textTypes } from '../../../common/components/Text';

import './EditOutlineDescription.scss';
import { svgToolsListNew } from '../../../DrawingTool/constants/SvgTools'

const EditOutlineDescription = () => {
  const currentTool = useSelector(state => state.drawingTool.currentTool)
  const tools = svgToolsListNew.flatMap(toolCategory => toolCategory.table);
  const selectedTool = useMemo(() => {

    const t =  tools.find(t => t.tool === currentTool);
    return t
  }, [currentTool, tools]);

  console.log("EditOutlineDescription");
  console.log(selectedTool)
  console.log(currentTool)

  if (currentTool === null)
    return;



  return (
    <>
      <Text outerClassName={'step-sidebar-title'} component={textTypes.title}>{selectedTool.toolname}</Text>
      <div className={'step-sidebar-container'}>
        {selectedTool.tooldescription ?
          <div className={'step-sidebar-descriptions'}>

                <Text outerClassName={'step-sidebar-description-text'} component={textTypes.paragraph} multiline >
                  {selectedTool.tooldescription}
                </Text>
                <div className={'step-sidebar-description-separator'} />

          </div> : null}

        {selectedTool.shortcuts ?
          <div className={'step-sidebar-shortcuts'}>
            <Text component={textTypes.paragraph} outerClassName={'step-sidebar-shortcuts-title'}>Shortcuts</Text>
            {selectedTool.shortcuts.map((shortcut, index) => (
              <Text component={textTypes.paragraph} outerClassName={'step-sidebar-shortcut'} key={index}>{shortcut}</Text>
            ))}
          </div> : null}
      </div>
    </>
  );
};

export default EditOutlineDescription;
